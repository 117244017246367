import * as yup from "yup";
import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED } from "../../../../main/common/constants/defaultValidations";
import {
  checkForNumericChars,
  checkForSpecialChars,
} from "../../../../main/common/utils/checkStrings";
import { validateDocumentPerSize } from "../../../../main/common/utils/validateDocument";

export const clientSchema = yup
  .object({
    fullName: yup
      .string()
      .required("Esse campo é obrigatório")
      .min(4, "Mínimo de 4 caracteres"),
    maritalStatus: yup.string().when("type", {
      is: "PF",
      then: yup.string().required("Esse campo é obrigatório"),
      otherwise: yup.string().nullable(),
    }),
    document: yup
      .string()
      .required("Esse campo é obrigatório")
      .test("Documento Válido", "Documento informado é inválido.", (value) =>
        validateDocumentPerSize(value ?? "")
      ),
    rg: yup
      .string()
      .when(["cnh", "oab", "type"], {
        is: (cnh: string, oab: string, type: string) =>
          !cnh && !oab && type === "PF",
        then: yup
          .string()
          .required("Preencha ao menos um dos documentos (RG, CNH, OAB)"),
      })
      .nullable(),
    ie: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .nullable(),
    cnh: yup.string().nullable(),
    oab: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .nullable(),
    email: yup
      .string()
      .required("Esse campo é obrigatório")
      .email("Digite um e-mail válido."),
    sendDecryptedFiles: yup.boolean().nullable(),
    cep: yup.string().required("Esse campo é obrigatório"),
    state: yup.string().required("Esse campo é obrigatório"),
    city: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) =>
          !checkForSpecialChars(
            value ?? "",
            DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED
          )
      )
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    district: yup.string().required("Esse campo é obrigatório"),
    address: yup.string().required("Esse campo é obrigatório"),
    addressNumber: yup.string().required("Esse campo é obrigatório"),
    additionalInformation: yup.string().nullable(),
  })
  .required();
