import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AddressForm } from "../../../../../main/components/AdressForm";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { DatePicker } from "../../../../../main/components/DateTimePicker/DatePicker";
import { InputMask } from "../../../../../main/components/InputMask";
import { TLargexl } from "../../../../../main/components/Tipography";
import { IClient, IClientData } from "../../../../../types/main/client";
import { ClientFormTextPerType } from "../../constants/ClientType.constant";
import { useClientForm } from "../../hooks/useClientForm";
import { clientSchema } from "../../validation/ClientSchema";
import { ClientPF } from "./PF";
import { ClientPJ } from "./PJ";

interface IClientForm {
  clientType: "PF" | "PJ";
  existingClient?: IClient;
  isReadOnly?: boolean;
  modal?: boolean;
}

export const ClientForm = ({
  clientType,
  existingClient,
  isReadOnly,
  modal,
}: IClientForm) => {
  const formMethods = useForm<IClientData>({
    defaultValues: {
      type: clientType,
      sendDecryptedFiles: false,
    },
    resolver: yupResolver(clientSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { isLoading, onSubmit, maritalList } = useClientForm({
    reset,
    clientType,
    editClientUUID: existingClient?.uuid,
  });

  useEffect(() => {
    if (existingClient && maritalList) {
      reset({
        fullName: existingClient.fullName,
        email: existingClient.email,
        document: existingClient.document,
        birthDate: existingClient.birthDate,
        cep: existingClient.cep,
        state: existingClient.state,
        city: existingClient.city,
        district: existingClient.district,
        address: existingClient.address,
        addressNumber: existingClient.addressNumber,
        additionalInformation: existingClient.additionalInformation,

        maritalStatus: existingClient.maritalStatus?.uuid,
        ie: existingClient.ie ?? undefined,
        rg: existingClient.rg ?? undefined,
        oab: existingClient.oab ?? undefined,
        cnh: existingClient.cnh ?? undefined,
        type: existingClient.type,
        sendDecryptedFiles: !!existingClient?.sendDecryptedFiles,
      });
    }
  }, [existingClient, maritalList, reset]);

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <TLargexl mb="1em" alignSelf="flex-start">
          Dados - {ClientFormTextPerType[clientType].header}
        </TLargexl>
        <Grid
          templateRows="(3, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={6}
          minH="200px"
        >
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.fullName}>
              <FormLabel htmlFor="fullName">
                {ClientFormTextPerType[clientType].labelName}
              </FormLabel>
              <Input
                id="fullName"
                {...register("fullName")}
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
              />
              <FormErrorMessage>
                {errors.fullName && errors.fullName.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.birthDate}>
              <FormLabel htmlFor="birthDate">
                {ClientFormTextPerType[clientType].labelDate}
              </FormLabel>
              <DatePicker fieldname="birthDate" isDisabled={isReadOnly} />
              <FormErrorMessage>
                {errors.birthDate && errors.birthDate.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.document}>
              <FormLabel htmlFor="document">
                {ClientFormTextPerType[clientType].labelDocument}
              </FormLabel>
              <InputMask
                fieldname="document"
                format={ClientFormTextPerType[clientType].documentMask}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.document && errors.document.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          {clientType === "PF" && (
            <ClientPF isReadOnly={!!isReadOnly} maritalList={maritalList} />
          )}
          {clientType === "PJ" && <ClientPJ isReadOnly={!!isReadOnly} />}

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">E-mail</FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                id="email"
                type="email"
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="sendDecryptedFiles">
                Enviar arquivos desprotegidos{" "}
                <Tooltip
                  label="Marque 'SIM' para enviar arquivos sem senha para este cliente."
                  fontSize="md"
                >
                  <InfoOutlineIcon />
                </Tooltip>
              </FormLabel>
              <BooleanRadioGroup
                isReadOnly={isReadOnly}
                defaultChecked={!!existingClient?.sendDecryptedFiles}
                fieldname="sendDecryptedFiles"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
              <FormErrorMessage>
                {errors.sendDecryptedFiles && errors.sendDecryptedFiles.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          minH="300px"
          templateRows="(2, 1fr)"
          templateColumns="repeat(12, 1fr)"
          gap={4}
        >
          <AddressForm
            isDisabled={isReadOnly}
            uuidToEdit={existingClient?.uuid}
          />
        </Grid>
        <Flex w="100%" justify="flex-end">
          <ButtonGroup spacing="58" alignItems="center">
            {!modal && (
              <Link to="/clientlist">
                <Button variant="link" color="gray.700">
                  Cancelar
                </Button>
              </Link>
            )}

            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {existingClient ? "Editar cliente" : "Cadastrar cliente"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </Flex>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
